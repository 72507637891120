@keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  @keyframes swing {
    0%, 100% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(10deg);
    }
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .pageWidget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }

  .iconContainer {
    display: block;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1s ease, transform 1s ease;
  }

   .iconContainer.visible {
    opacity: 1;
    transform: scale(1);
    animation: pulse 2s infinite;
  }
  
   .iconContainer.hidden {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .pageWidget__img {
    width: 75px;
    height: 75px;
    transition: transform 0.3s ease;
  }

  @media screen and (max-width: 600px) {
    .pageWidget__img {
      width: 45px;
      height: 45px;
    }
    
  }
  
  .pageWidget__img:hover {
    animation: bounce 0.5s ease;
  }
  
  .popupContainer {
    position: absolute;
    bottom: 60px;
    right: 0;
    width: max-content;
  }
  
  .popup {
    padding: 10px 15px;
    background: var(--primary-bg-color);
    color: var(--color-white);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .pageWidget:hover .popup {
    opacity: 1;
    transform: translateY(0);
  }