/* Контейнер группы чекбоксов */
.quiz__checkbox-group {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  gap: 10px;
  margin: 20px 0;
}

/* Опция чекбокса с текстом */
.quiz__checkbox-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  font: var(--font-ml);
  color: var(--color-grey);
  transition: color 0.25s ease-out;
}

/* Стили для input (чекбокса) */
.quiz__checkbox-input {
  position: relative;
  appearance: none;
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
  border: 0.15rem solid var(--color-grey);
  border-radius: 0.25rem;
  background-color: transparent;
  transition: border-color 0.25s ease-out, background-color 0.25s ease-out;
  cursor: pointer;
}

.quiz__checkbox-input:hover {
  border-color: var(--color-accent);
}

.quiz__checkbox-input:checked {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
}
.quiz__checkbox-input:checked::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.25rem;
  border-left: 0.125rem solid var(--color-white);
  border-bottom: 0.125rem solid var(--color-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* Стили для текста рядом с чекбоксом */
.quiz__checkbox-label {
  transition: color 0.25s ease-out;
}

.quiz__checkbox-option:hover .quiz__checkbox-label {
  color: var(--color-l-grey);
}

.quiz__checkbox-input:checked + .quiz__checkbox-label {
  color: var(--color-white2);
}
