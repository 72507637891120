.quiz__wrapper {
  opacity: 0;
  padding: 40px 0px;
}

.quiz__wrapper.animation {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.quiz {
  margin: auto;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 0 16px;
}

.quiz__wrapper {
  display: grid;
  grid-template-areas:
    "title"
    "subtitle"
    "question"
    "controls";
  justify-content: start;
  max-width: 1440px;
  box-sizing: initial;
}

.quiz__title {
  grid-area: title;
  margin: 0;
  font: var(--font-h-l);
  white-space: wrap;

}

.quiz__subtitle {
  grid-area: subtitle;
  margin: 24px 0 8px;
  line-height: 1.6;
  font: var(--font-ml);
}


.quiz__question {
  margin: 48px 0 0 ;
}

.quiz__question-title {
  font-size: 1.25rem;
  margin: 18px 0;
}

.quiz__button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background: transparent;
  color: var(--color-grey);
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.25s ease-out, border-bottom 0.25s ease-out;
}

.quiz__button:hover {
  color: var(--color-accent);
  border-bottom: 1px solid var(--color-accent);
}

.quiz__button_prev {
  margin-left: 0;
}

.quiz__button_next {
  margin-left: auto;
  padding: 10px 20px;
  border: 1px solid var(--color-accent);
  background-color: transparent;
  color: var(--color-accent);
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.quiz__button_next:hover {
  background-color: var(--color-accent);
  color: var(--color-black);
}

.quiz__button_next:active {
  background-color: var(--color-accent);
  color: var(--color-white);
  opacity: 0.9;
}

.quiz__button_submit {
  color: var(--color-white);
  font-weight: 500;
  letter-spacing: 0.03rem;
}

@keyframes quiz__completion {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.quiz__completion {
  margin: 120px 0 0;
  opacity: 0;
  animation: quiz__completion 1s ease-out forwards;
}

.quiz__controls {
  gap: 20px;
  display: flex;
  width: max-content;
  margin: 30px 0 0;
}

@media screen and (max-width: 600px) {


  .quiz {
    padding: 0;
  }

  .quiz__title {
    font-size: clamp(1rem, 8vw, 2.06rem);
  }

  .quiz__subtitle {
    font: var(--font-m);
    margin: 16px 0;
    text-align: start;
  }

  .quiz__question {
    margin: 36px 0 0 ;
  }

  .quiz__question-title {
    margin: 12px 0;
  }

  .quiz__button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .quiz__button_next {
    padding: 8px 16px;
  }

  .quiz__controls {
    gap: 20px;
    display: flex;
    width: max-content;
    max-width: 80%;
    margin: 10px 0 0;
  }
  
  .quiz__completion {
    margin: 60px 0 0;
  }
}
