.download-link {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: var(--color-accent);
  cursor: pointer;
  display: inline;
  text-decoration: underline;
}

.download-link:hover {
  text-decoration: none;
  color: var(--color-visited);
}
