/* Контейнер группы радиокнопок */
.quiz__radio-group {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    gap: 10px;
    margin: 20px 0;
  }
  
  /* Радиокнопка с текстом */
  .quiz__radio-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    font: var(--font-ml);
    color: var(--color-grey);
    transition: color 0.25s ease-out;
  }
  
  /* Стили для input (радиокнопки) */
  .quiz__radio-input {
    appearance: none;
    width: 1rem;
    height: 1rem;
    margin-right: 10px;
    border: 0.15rem solid var(--color-grey);
    border-radius: 50%;
    background-color: transparent;
    transition: border-color 0.25s ease-out, background-color 0.25s ease-out;
    cursor: pointer;
  }
  
  .quiz__radio-input:checked {
    background-color: var(--color-accent);
    border-color: var(--color-accent);
  }
  
  .quiz__radio-input:hover {
    border-color: var(--color-accent);
  }
  
  /* Стили для текста рядом с радиокнопкой */
  .quiz__radio-label {
    transition: color 0.25s ease-out;
    width: 100%;
  }
  
  .quiz__radio-option:hover .quiz__radio-label {
    color: var(--color-l-grey);
  }
  
  .quiz__radio-input:checked + .quiz__radio-label {
    color: var(--color-white2);
  }
  