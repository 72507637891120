.quiz__contact-form {
  display: grid;
  grid-gap: 0 50px;
  grid-template-areas:
    "name    comment"
    "contact comment";
  grid-area: form;
  margin: 0 0 60px;
  justify-content: start;
}

.quiz__input,
.quiz__textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid var(--color-grey);
  background: transparent;
  font-size: 1rem;
  color: var(--color-grey);
  line-height: 1.5;
  transition: border-bottom 0.25s ease-out;
}

.quiz__input:focus,
.quiz__textarea:focus {
  outline: none;
  border-bottom: 1px solid transparent;
}

.quiz__input::placeholder,
.quiz__textarea::placeholder {
  opacity: 0.8;
  color: var(--color-grey-light);
}

.quiz__textarea {
  grid-area: comment;
  resize: none;
  min-height: 80px;
}

.quiz__input:nth-child(1) {
  grid-area: name;
}

.quiz__input:nth-child(2) {
  grid-area: contact;
}


@media screen and (max-width: 600px) {
  .quiz__contact-form {
    grid-template-areas:
      "name"
      "contact"
      "comment";
    grid-gap: 10px;
    padding: 0;
    margin: 0 0 50px;
  }

  .quiz__input,
  .quiz__textarea {
    padding: 8px;
  }
}
