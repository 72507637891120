.wrapper.carousel__main {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 200px auto 0;
  max-width: 1900px;
  opacity: 0;
  transition: opacity 1s ease-out;
  /* width: 100vw; */
}

@media screen and (max-width: 1000px) {
  .wrapper.carousel__main {
    max-width: 100vw;
    width: calc(100% - 30px);
    margin: 64px 15px 0;
  }
}

.wrapper.carousel__main.animation {
  opacity: 1;
}

.carousel__inner {
  display: flex;
  gap: 40px;
  overflow: hidden;
  overscroll-behavior: contain;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding-inline-start: 360px;
  touch-action: pan-x;
  touch-action: -ms-manipulation pan-x;
  touch-action: -webkit-manipulation pan-x;
}

@media screen and (max-width: 900px) {
  .carousel__inner {
    height: 325px;
    scroll-padding-inline-start: 0;
  }
}

.carousel__slide {
  display: flex;
  margin: auto 0;
  flex: 0 0 auto;
  height: 543px;
  width: 806px;
}

@media screen and (max-width: 1000px) {
  .carousel__slide {
    height: 400px;
    width: 600px;
  }
}

@media (max-width: 767px) and (orientation: portrait) {
  .carousel__slide {
    height: 250px;
    width: 380px;
  }
}

@media screen and (max-width: 600px) {
  .carousel__slide {
    height: 300px;
    width: 100%;
  }
}

.carousel__slide:first-child {
  margin-left: 360px;
}

@media screen and (max-width: 1000px) {
  .carousel__slide:first-child {
    margin-left: 0;
  }
}
@media screen and (min-width: 600px) {
  .carousel__slide:last-child {
    position: relative;
  }

  .carousel__slide:last-child::after {
    position: absolute;
    left: 100%;
    height: 1px;
    width: 400px;
    display: block;
    content: "";
  }
}

.carousel____imgWrapper {
  overflow: hidden;
  width: 100%;
}

.carousel__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease-out;
}

.carousel__slide:hover .carousel__img {
  transform: scale(1.2);
}

/* slide title animation */

.carousel__descriptionContainer {
  display: flex;
  flex-direction: row;
  transform: rotate(180deg);
  transition: all 0.4s ease-out;
  padding: 0 15px 0 0;
  height: 100%;
  writing-mode: vertical-rl;
}

.carousel__description {
  transition: all 0.4s ease-out;
  font: var(--font-ml);
}

.carousel__slide:hover .carousel__description {
  color: var(--color-accent);
}

@media screen and (max-width: 600px) {
  .carousel__description {
    font-size: 0.75rem;
  }
}

.carousel__descriptionLine {
  transition: all 0.4s ease-out;
  margin: 0 auto 22px;
  border-top: 1px solid var(--color-white);
  background-color: var(--color-white);
  width: 1px;
  height: 45px;
}

.carousel__slide:hover .carousel__descriptionLine {
  opacity: 0;
  margin: 0 auto 0;
  background-color: var(--color-accent);
  height: 0px;
}

.carousel__descriptionLine_after {
  opacity: 0;
  transition: all 0.4s ease-out;
  margin: 0 auto 22px;
  border-top: 1px solid var(--color-white);
  border: none;
  background-color: var(--color-white);
  width: 1px;
  height: 0px;
}

.carousel__slide:hover .carousel__descriptionLine_after {
  opacity: 1;
  margin: 22px auto 0;
  background-color: var(--color-accent);
  height: 45px;
}

/* end */

/* controls */

.carousel__indexContainer {
  display: flex;
  justify-content: center;
  margin: 48px auto 0 455px;
  width: fit-content;
}

@media screen and (max-width: 600px) {
  .carousel__indexContainer {
    display: none;
  }
}

.carousel__indexItem {
  margin: auto 9.5px;
  font: var(--font-xs);
  font-weight: 500;
}

.carousel__indexLine {
  margin: auto 9.5px;
  border: none;
  border-bottom: solid 1px var(--color-white);
  width: 26px;
  height: 0;
}

.carousel__arrow {
  position: relative;
  transition: all 2s ease-out;
  cursor: pointer;
  background-image: url(../../../shared/images/right-arrow.webp);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 25px;
  margin-right: 9.5px;
  margin-left: 9.5px;
}

.carousel__arrow:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  background-image: url(../../../shared/images/right-arrow_hover.webp);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: var(--color-black);
  width: 100%;
  height: 100%;
  content: "";
}

.carousel__arrow_left {
  background-image: url(../../../shared/images/left-arrow.webp);
}

.carousel__arrow_left::after {
  background-image: url(../../../shared/images/left-arrow_hover.webp);
}

.carousel__arrow:hover:after {
  opacity: 1;
}

.carousel__main .progressBar {
  display: none;
}

@media screen and (max-width: 600px) {
  .carousel__main .progressBar {
    display: block;
    margin: 0 auto;
    width: calc(100%-30px);
  }
}
