
  .quiz-progress {
    width: 100%;
    height: 6px;
    background-color: var(--color-dark-grey);
    border-radius: 3px;
    overflow: hidden;
    margin: 16px 0;
  }

  @media screen and (max-width: 600px) {
    .quiz-progress {
      height: 4px;
      margin: 10px 0;
    }
  }
  
  .quiz-progress__bar {
    height: 100%;
    background-color: var(--color-accent);
    transition: width 0.3s ease;
  }
  